const settings = {
    // API_URL: "http://localhost:3004/api/",
    // API_URL: "https://api.mobility-app.com/api/",
    API_URL: "https://mobility-app.com/api/",
    // API_URL: "https://mobility3.desarrollodeproinf.com/api/",
    ACTIVE_TOKEN: "eyJhbGciOiJIUzI1NiJ9.amVyaXNtYXJ2QGdtYWlsLmNvbQ.ty-QB5b4NLvipLzhFRJBul_Ps5ZVhfkO4_GOevxQb5U",
  };
  
  export default settings;
  // Lo001*-c lo001.wf@gmail.com

//  18140660
